import { IconButton } from './sidebarStyle'
import {
    TRIGGER_COLLECTION_PAGE_VIEWED,
    TRIGGER_PROFILE_PAGE_VIEWED,
} from '@/helpers/events'
import { useLoadTopItems } from '@/hooks/useLoadTopItems'
// import { useRouter } from 'next/router'
import { updateNav } from '@/store/actions/home'
import { setShowLoginPrompt } from '@/store/actions/player'
import {
    updateFocus,
    updateSearchTerm,
    getSearchResult,
} from '@/store/actions/search'
import { _activeNav } from '@/store/selectors/home'
import * as playerSelectors from '@/store/selectors/player'
import { NAVTYPES } from '@/store/types/home'
import { NavItem, Title } from '@/styles/styles.sidebar'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

export default function Sidebar() {
    const [expanded, setExpanded] = useState(false)

    const { data: session, status } = useSession()
    const isSubscribed = session?.userInfo?.isSubscribed

    const activeNav = useSelector(_activeNav)
    // const router = useRouter()

    const dispatch = useDispatch()

    const { topItems } = useLoadTopItems(session?.token)

    const playing = useSelector(playerSelectors._playing)
    const [playedOnce, setPlayedOnce] = useState(false)

    useEffect(() => {
        if (playing) {
            setPlayedOnce(true)
        }
    }, [playing])

    const topPicksArrFirst = topItems.slice(0, 4)
    const topPicksArrCollapsed = topItems.slice(5, 10)

    const isNavActive = (currentNav) =>
        activeNav === currentNav ? true : false

    const switchNav = (currentNav) => {
        switch (currentNav) {
            case NAVTYPES.HOME: {
                dispatch(updateNav(currentNav))
                break
            }
            case NAVTYPES.GENRE: {
                dispatch(updateNav(currentNav))
                break
            }
            case NAVTYPES.SEARCH: {
                dispatch(updateNav(currentNav))
                dispatch(updateFocus(false))
                dispatch(updateSearchTerm(''))
                dispatch(getSearchResult({ data: [] }))
                // router.push('/search')
                break
            }
            case NAVTYPES.COLLECTION: {
                TRIGGER_COLLECTION_PAGE_VIEWED()
                if (status === 'authenticated' && session?.isLoggedin) {
                    dispatch(updateNav(currentNav))
                } else {
                    dispatch(setShowLoginPrompt(true))
                }
                break
            }
            case NAVTYPES.PROFILE: {
                TRIGGER_PROFILE_PAGE_VIEWED()
                if (status === 'authenticated' && session?.isLoggedin) {
                    dispatch(updateNav(currentNav))
                } else {
                    dispatch(setShowLoginPrompt(true))
                }
                break
            }
            case NAVTYPES.SYARAT_DAN_KETENTUAN: {
                dispatch(updateNav(currentNav))
                break
            }
            case NAVTYPES.KEBIJAKAN_PRIVASI: {
                dispatch(updateNav(currentNav))

                break
            }
            default:
                if (status === 'authenticated' && session?.isLoggedin) {
                    if (currentNav) dispatch(updateNav(currentNav))
                } else {
                    dispatch(setShowLoginPrompt(true))
                }

            // dispatch(setShowDownloadPrompt(true))
        }
    }
    return (
        <div
            className="sidebar"
            style={{ height: playedOnce ? 'calc(100% - 66px)' : '100%' }}
        >
            <div
                className="sidebarLogo"
                style={{
                    background: isSubscribed
                        ? 'linear-gradient(274deg, #141414 15.22%, #9C55D5 141.17%)'
                        : 'none',
                }}
            >
                <Link href={'/'} prefetch={false}>
                    <a>
                        <img
                            src={'/img/noice-logo-transparent.svg'}
                            alt="Noice Logo"
                            width="100%"
                            height="29"
                            loading="lazy"
                        />
                    </a>
                </Link>
            </div>

            {isSubscribed ? null : <div className="sidebar-divider"></div>}

            <div className="sidebarSection">
                <nav>
                    <Link href={'/'} prefetch={false}>
                        <a
                            className={
                                isNavActive(NAVTYPES.HOME) ? `active` : ''
                            }
                        >
                            <NavItem onClick={() => switchNav(NAVTYPES.HOME)}>
                                <img
                                    alt="home logo"
                                    src="/img/home-logo-white.svg"
                                    style={{ width: '24px', height: '24px' }}
                                />
                                <Title active={isNavActive(NAVTYPES.HOME)}>
                                    {NAVTYPES.HOME}
                                </Title>
                            </NavItem>
                        </a>
                    </Link>

                    <Link href="/search" prefetch={false}>
                        <a
                            className={
                                isNavActive(NAVTYPES.SEARCH) ? `active` : ''
                            }
                        >
                            <NavItem onClick={() => switchNav(NAVTYPES.SEARCH)}>
                                <img
                                    alt="genre logo"
                                    src="/img/smile-white.svg"
                                />
                                <Title active={isNavActive(NAVTYPES.SEARCH)}>
                                    Genre
                                </Title>
                            </NavItem>
                        </a>
                    </Link>
                </nav>
            </div>

            <div className="sidebar-divider"></div>

            <div className="sidebarSection">
                <Link
                    href={
                        status === 'authenticated' && session?.isLoggedin
                            ? '/collection'
                            : '#/'
                    }
                    prefetch={false}
                >
                    <a
                        className={
                            isNavActive(NAVTYPES.COLLECTION) ? `active` : ''
                        }
                    >
                        <NavItem onClick={() => switchNav(NAVTYPES.COLLECTION)}>
                            <Title active={isNavActive(NAVTYPES.COLLECTION)}>
                                {NAVTYPES.COLLECTION}
                            </Title>

                            <img
                                style={{ width: '16px', height: '16px' }}
                                alt="right arrow"
                                src="/img/arrow-right.svg"
                            />
                        </NavItem>
                    </a>
                </Link>

                <Link
                    href={
                        status === 'authenticated' && session?.isLoggedin
                            ? '/collection/history'
                            : '#/'
                    }
                    prefetch={false}
                >
                    <a
                        className={
                            isNavActive(NAVTYPES.COLLECTION_HISTORY)
                                ? `active`
                                : ''
                        }
                    >
                        <NavItem
                            onClick={() =>
                                switchNav(NAVTYPES.COLLECTION_HISTORY)
                            }
                        >
                            <img alt="genre logo" src="/img/clock-white.svg" />
                            <Title
                                active={isNavActive(
                                    NAVTYPES.COLLECTION_HISTORY
                                )}
                            >
                                {NAVTYPES.COLLECTION_HISTORY}
                            </Title>
                        </NavItem>
                    </a>
                </Link>

                <Link
                    href={
                        status === 'authenticated' && session?.isLoggedin
                            ? '/collection/likes'
                            : '#/'
                    }
                    prefetch={false}
                >
                    <a
                        className={
                            isNavActive(NAVTYPES.COLLECTION_LIKES)
                                ? `active`
                                : ''
                        }
                    >
                        <NavItem
                            onClick={() => switchNav(NAVTYPES.COLLECTION_LIKES)}
                        >
                            <img alt="genre logo" src="/img/like-white.svg" />
                            <Title
                                active={isNavActive(NAVTYPES.COLLECTION_LIKES)}
                            >
                                {NAVTYPES.COLLECTION_LIKES}
                            </Title>
                        </NavItem>
                    </a>
                </Link>

                {status === 'authenticated' && session?.isLoggedin && (
                    <>
                        <Link href={'/purchased-episodes'} prefetch={false}>
                            <a
                                className={
                                    isNavActive(NAVTYPES.PURCHASED_EPISODES)
                                        ? `active`
                                        : ''
                                }
                            >
                                <NavItem
                                    onClick={() =>
                                        switchNav(NAVTYPES.PURCHASED_EPISODES)
                                    }
                                >
                                    <img
                                        alt="genre logo"
                                        src="/img/cart-white.svg"
                                    />
                                    <Title
                                        active={isNavActive(
                                            NAVTYPES.PURCHASED_EPISODES
                                        )}
                                    >
                                        {NAVTYPES.PURCHASED_EPISODES}
                                    </Title>
                                </NavItem>
                            </a>
                        </Link>

                        <Link href={'/collection/podcast'} prefetch={false}>
                            <a
                                className={
                                    isNavActive(NAVTYPES.COLLECTION_PODCAST)
                                        ? `active`
                                        : ''
                                }
                            >
                                <NavItem
                                    onClick={() =>
                                        switchNav(NAVTYPES.COLLECTION_PODCAST)
                                    }
                                >
                                    <img
                                        alt="genre logo"
                                        src="/img/podcast-white.svg"
                                    />
                                    <Title
                                        active={isNavActive(
                                            NAVTYPES.COLLECTION_PODCAST
                                        )}
                                    >
                                        {NAVTYPES.COLLECTION_PODCAST}
                                    </Title>
                                </NavItem>
                            </a>
                        </Link>

                        <Link href={'/collection/audioseries'} prefetch={false}>
                            <a
                                className={
                                    isNavActive(NAVTYPES.COLLECTION_AUDIOSERIES)
                                        ? `active`
                                        : ''
                                }
                            >
                                <NavItem
                                    onClick={() =>
                                        switchNav(
                                            NAVTYPES.COLLECTION_AUDIOSERIES
                                        )
                                    }
                                >
                                    <img
                                        alt="genre logo"
                                        src="/img/audioseries-white.svg"
                                    />
                                    <Title
                                        active={isNavActive(
                                            NAVTYPES.COLLECTION_AUDIOSERIES
                                        )}
                                    >
                                        {NAVTYPES.COLLECTION_AUDIOSERIES}
                                    </Title>
                                </NavItem>
                            </a>
                        </Link>

                        <Link href={'/collection/radio'} prefetch={false}>
                            <a
                                className={
                                    isNavActive(NAVTYPES.COLLECTION_RADIO)
                                        ? `active`
                                        : ''
                                }
                            >
                                <NavItem
                                    onClick={() =>
                                        switchNav(NAVTYPES.COLLECTION_RADIO)
                                    }
                                >
                                    <img
                                        alt="genre logo"
                                        src="/img/radio-white.svg"
                                    />
                                    <Title
                                        active={isNavActive(
                                            NAVTYPES.COLLECTION_RADIO
                                        )}
                                    >
                                        {NAVTYPES.COLLECTION_RADIO}
                                    </Title>
                                </NavItem>
                            </a>
                        </Link>

                        <Link href={'/collection/audiobook'} prefetch={false}>
                            <a
                                className={
                                    isNavActive(NAVTYPES.COLLECTION_AUDIOBOOK)
                                        ? `active`
                                        : ''
                                }
                            >
                                <NavItem
                                    onClick={() =>
                                        switchNav(NAVTYPES.COLLECTION_AUDIOBOOK)
                                    }
                                >
                                    <img
                                        alt="genre logo"
                                        src="/img/audiobook-white.svg"
                                    />
                                    <Title
                                        active={isNavActive(
                                            NAVTYPES.COLLECTION_AUDIOBOOK
                                        )}
                                    >
                                        {NAVTYPES.COLLECTION_AUDIOBOOK}
                                    </Title>
                                </NavItem>
                            </a>
                        </Link>

                        {/* <Link href={'/collection/class'} prefetch={false}>
                            <a
                                className={
                                    isNavActive(NAVTYPES.COLLECTION_KELAS)
                                        ? `active`
                                        : ''
                                }
                            >
                                <NavItem
                                    onClick={() =>
                                        switchNav(NAVTYPES.COLLECTION_KELAS)
                                    }
                                >
                                    <img
                                        alt="genre logo"
                                        src="/img/kelas-white.svg"
                                    />
                                    <Title
                                        active={isNavActive(
                                            NAVTYPES.COLLECTION_KELAS
                                        )}
                                    >
                                        {NAVTYPES.COLLECTION_KELAS}
                                    </Title>
                                </NavItem>
                            </a>
                        </Link> */}

                        {/* <Link href={'/collection/playlist'} prefetch={false}>
                            <a
                                className={
                                    isNavActive(NAVTYPES.COLLECTION_PLAYLIST)
                                        ? `active`
                                        : ''
                                }
                            >
                                <NavItem
                                    onClick={() =>
                                        switchNav(NAVTYPES.COLLECTION_PLAYLIST)
                                    }
                                >
                                    <img
                                        alt="genre logo"
                                        src="/img/music-white.svg"
                                    />
                                    <Title
                                        active={isNavActive(
                                            NAVTYPES.COLLECTION_PLAYLIST
                                        )}
                                    >
                                        {NAVTYPES.COLLECTION_PLAYLIST}
                                    </Title>
                                </NavItem>
                            </a>
                        </Link> */}
                    </>
                )}
            </div>

            {status === 'authenticated' &&
                session?.isLoggedin &&
                !!topItems?.length && (
                    <>
                        <div className="sidebar-divider"></div>

                        <div className="sidebarSection">
                            <Link href="#" passHref prefetch={false}>
                                <a rel="noreferrer" className="disable-hover">
                                    <NavItem>
                                        <Title>Siap Diputar</Title>
                                    </NavItem>
                                </a>
                            </Link>

                            {topPicksArrFirst.map((item) => {
                                return (
                                    <Link
                                        key={item.id}
                                        href={`/${item?.route?.entityType}/${item?.route?.entityId}`}
                                        passHref
                                        prefetch={false}
                                    >
                                        <a target="_blank" rel="noreferrer">
                                            <NavItem>
                                                <img
                                                    alt="item photo"
                                                    src={
                                                        item?.imageMeta?.['40']
                                                    }
                                                    style={{
                                                        width: '24px',
                                                        height: '24px',
                                                    }}
                                                />
                                                <Title
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    {item.title || ''}
                                                </Title>
                                            </NavItem>
                                        </a>
                                    </Link>
                                )
                            })}

                            <div
                                style={{
                                    maxHeight:
                                        expanded &&
                                        !!topPicksArrCollapsed?.length
                                            ? '1000px'
                                            : '0px',
                                    overflow: 'hidden',
                                    transition: 'max-height 0.5s ease-in-out',
                                }}
                            >
                                {topPicksArrCollapsed.map((item) => {
                                    return (
                                        <Link
                                            key={item.id}
                                            href={`/${item?.route?.entityType}/${item?.route?.entityId}`}
                                            passHref
                                            prefetch={false}
                                        >
                                            <a target="_blank" rel="noreferrer">
                                                <NavItem>
                                                    <img
                                                        alt="item photo"
                                                        src={
                                                            item?.imageMeta?.[
                                                                '40'
                                                            ]
                                                        }
                                                        style={{
                                                            width: '24px',
                                                            height: '24px',
                                                        }}
                                                    />
                                                    <Title
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow:
                                                                'ellipsis',
                                                        }}
                                                    >
                                                        {item.title || ''}
                                                    </Title>
                                                </NavItem>
                                            </a>
                                        </Link>
                                    )
                                })}
                            </div>

                            {!!topPicksArrCollapsed?.length && (
                                <IconButton
                                    onClick={() => setExpanded(!expanded)}
                                >
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="disable-hover"
                                    >
                                        <NavItem>
                                            <img
                                                alt="item photo"
                                                src="/img/down-arrow-white.svg"
                                                style={{
                                                    width: '24px',
                                                    height: '24px',
                                                    transform: `rotate(${
                                                        expanded
                                                            ? '180deg'
                                                            : '0deg'
                                                    })`,
                                                    transition:
                                                        'transform 0.2s',
                                                }}
                                            />

                                            <Title>
                                                {expanded
                                                    ? 'Lebih Sedikit'
                                                    : 'Lebih Banyak'}
                                            </Title>
                                        </NavItem>
                                    </a>
                                </IconButton>
                            )}
                        </div>
                    </>
                )}

            <div className="sidebar-divider"></div>

            <div className="sidebarSection">
                <nav>
                    <Link href={'/tnc'} prefetch={false}>
                        <a
                            className={
                                isNavActive(NAVTYPES.SYARAT_DAN_KETENTUAN)
                                    ? `active`
                                    : ''
                            }
                        >
                            <NavItem
                                onClick={() =>
                                    switchNav(NAVTYPES.SYARAT_DAN_KETENTUAN)
                                }
                            >
                                <img src="/img/document-white.svg" alt="" />
                                <Title
                                    active={isNavActive(
                                        NAVTYPES.SYARAT_DAN_KETENTUAN
                                    )}
                                >
                                    {NAVTYPES.SYARAT_DAN_KETENTUAN}
                                </Title>
                            </NavItem>
                        </a>
                    </Link>

                    <Link href={'/policy'} prefetch={false}>
                        <a
                            className={
                                isNavActive(NAVTYPES.KEBIJAKAN_PRIVASI)
                                    ? `active`
                                    : ''
                            }
                        >
                            <NavItem
                                onClick={() =>
                                    switchNav(NAVTYPES.KEBIJAKAN_PRIVASI)
                                }
                            >
                                <img src="/img/document-white.svg" alt="" />

                                <Title
                                    active={isNavActive(
                                        NAVTYPES.KEBIJAKAN_PRIVASI
                                    )}
                                >
                                    {NAVTYPES.KEBIJAKAN_PRIVASI}
                                </Title>
                            </NavItem>
                        </a>
                    </Link>

                    <Link
                        href={`mailto:support@noice.id?subject=[Web Support]&body=${session?.userInfo?.displayName}%0A${session?.userInfo?.userName}`}
                        prefetch={false}
                    >
                        <a>
                            <NavItem
                                onClick={() =>
                                    switchNav(NAVTYPES.KEBIJAKAN_PRIVASI)
                                }
                            >
                                <img src="/img/question-white.svg" alt="" />

                                <Title>Support</Title>
                            </NavItem>
                        </a>
                    </Link>
                </nav>
            </div>

            <div className="sidebar-divider"></div>

            <div className="sidebarSection">
                {/* <div className="sidebarHeading">Dengerin dari aplikasi</div> */}

                <Link href="#" passHref prefetch={false}>
                    <a rel="noreferrer" className="disable-hover">
                        <NavItem>
                            <Title>Install Noice</Title>
                        </NavItem>
                    </a>
                </Link>

                <nav>
                    <Link
                        href="https://play.google.com/store/apps/details?id=noice.app"
                        passHref
                        prefetch={false}
                    >
                        <a target="_blank" rel="noreferrer">
                            <NavItem>
                                <img
                                    alt="item photo"
                                    src="/img/android-icon-white.svg"
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                        transform: `rotate(${
                                            expanded ? '180deg' : '0deg'
                                        })`,
                                        transition: 'transform 0.2s',
                                    }}
                                />
                                <Title>Android</Title>
                            </NavItem>
                        </a>
                    </Link>
                    <Link
                        href="https://apps.apple.com/id/app/noice-podcast-radio-musik/id1484372791?l=id"
                        passHref
                        prefetch={false}
                    >
                        <a target="_blank" rel="noreferrer">
                            <NavItem>
                                <img
                                    alt="item photo"
                                    src="/img/ios-icon-white.svg"
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                        transform: `rotate(${
                                            expanded ? '180deg' : '0deg'
                                        })`,
                                        transition: 'transform 0.2s',
                                    }}
                                />
                                <Title>IOS</Title>
                            </NavItem>
                        </a>
                    </Link>
                </nav>
            </div>
        </div>
    )
}
