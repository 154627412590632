import { getFetcher } from '@/utils/auth'
import useSWR from 'swr'

const url = `segment-api/segment/${process.env.NEXT_PUBLIC_SEGMENT_ID}`

export const useLoadTopItems = (token) => {
    const { data, error, isValidating, mutate } = useSWR(
        token && url ? [url, token] : null,
        getFetcher,
        { revalidateOnFocus: false }
    )

    const topItemsNotLoadedYet = !data

    const loading = !error && isValidating

    const topItems = data?.data?.[0]?.content || []

    return { topItems, topItemsNotLoadedYet, loading, mutate }
}
