import { RowFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const NavItem = styled.div`
    ${RowFlex}
    cursor: pointer;
    gap: 1rem;
    align-items: center;
`
export const Title = styled.span`
    font-size: 1rem;
    color: white;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.5px;
    letter-spacing: 0.1px;
`
